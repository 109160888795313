/*--------- header Css -----------*/
.main-header {
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
    transition: 300ms;
    position: fixed;
    z-index: 99999;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
}
.mobile-toggle{
    display: none;
}
.main-header:not(.fixed-header) .header-search-engine {
    display: none;
}

.main-header .container{
    max-width: calc(100% - 4%);
}

.fixed-header {
    position: fixed;
    visibility: visible;
    opacity: 1;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 0px 0px;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.952941);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-header .logo-box {
    position: relative;
}

.main-header .logo-box .logo {
    background: #fff;
    border: 2px solid #002A0F;
    border-top: 0;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    max-width: 150px;
    top: 0;
    left: 0;
    z-index: 999;
}

.main-header .logo-box .logo a {
    padding: 10px 10px;
    display: block;
    width: 100%;
    text-align: center;
}

.main-header .logo-box .logo img {
    width: 90px;
}

.main-header .navigation-bar {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
}

.main-header .navigation-bar ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}

.main-header .navigation-bar ul li {
    margin-right: 30px;
}

.main-header .navigation-bar ul li:last-child {
    margin: 0;
}
.main-header .navigation-bar ul li .join-healer-btn{
    background-color: #F5D823;
    color: #002A0F;
    padding: 10px 30px;
    display: inline-flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    border: 2px solid #F5D823;
    border-radius: 100px;
    /* text-transform: capitalize; */
    transition: all 0.3s ease-in;
}
.main-header .navigation-bar ul li .join-healer-btn:hover{
    color: #F5D823;
    background-color: #002A0F;
    border-color:#002A0F ;
}

.main-header .navigation-bar ul li a {
    color: #333;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    padding: 10px 0;
}

.main-header .navigation-bar ul li a:hover {
    color: #002A0F;
}

.main-header .navigation-bar .theme-btn {
    padding: 5px 5px 5px 25px;    
}
.main-header .navigation-bar .dropdown-login{
   position: relative;
}
.main-header .navigation-bar .dropdown-login{
    padding: 20px 0;
}
.main-header .navigation-bar .dropdown-login .dropdown-btn.theme-btn:hover i{
    transform: translateX(0);
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-btn.user-icon .dropdown-login{
    display: block;
    border: 4px solid #002a11;
    border-radius: 100%;
    width: 65px;
    height: 65px;
    overflow: hidden;
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-btn.user-icon{
    border: 2px solid #002A0F;
    border-radius: 100%;
    display: block;
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-btn.user-icon img{
    border-radius: 100%;
    height: 45px;
    width: 45px;
    object-fit: cover;
    object-position: top center;
}
.main-header .navigation-bar .dropdown-login .dropdown-content{
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0;
    opacity: 0;
    display: block;
    z-index: 999;
    min-width: 200px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -ms-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 7px 10px -1px rgb(0 0 0 / 20%);
    visibility: hidden;
    overflow:hidden;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li{
    margin: 0;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li>a{
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid #e9e9e9;
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    transition: all 500ms ease;
    position: relative;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li>button{
    padding: 10px 15px 10px 15px !important;
    border-bottom: 1px solid #e9e9e9 !important;
    display: block !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #000 !important;
    transition: all 500ms ease;
    position: relative !important;
    background: #fff;
    border: 0;
    width: 100%;
    text-align: start;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li>button span{
    transition: all 500ms ease;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li:last-child>a{
    border: 0;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li>a::before{
    position: absolute;
    content: "\ea6c";
    left: 30px;
    right: inherit;
    top: 12px;
    display: block;
    line-height: 24px;
    font-size: 16px;
    font-family: "remixicon";
    font-weight: 900;
    font-size: 12px;
    transition: .5s;
    opacity: 0;
    color: #000;
    transition: all 500ms ease;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li>a:hover{
    padding-left: 46px !important;
    background: #fdf8f4;
    font-weight:600;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li>button:hover{
    background: #fdf8f4;
    font-weight:600;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li>button:hover span{
    padding-left: 20px !important;
    font-weight:600;
}
.main-header .navigation-bar .dropdown-login .dropdown-content>li>a:hover::before{
    opacity: 1;
}
.main-header .navigation-bar .dropdown-login:hover .dropdown-content{
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content{
    right: 0;
    left: inherit;
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a::before{
    content: unset;
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a:hover{
    padding-left: 15px !important;   
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a>i{
    line-height: 24px;
    font-size: 18px;
    color: #000;
    margin-right: 10px;
    transition: all 500ms ease;
}
.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a:hover>i{
    margin-right: 20px;
    font-weight:600;
}
.header-search-engine {
    position: relative;
    z-index: 9999;
}
.bg-search::before{
    content: " ";
    background-color: rgb(0 0 0 / 48%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
.header-search-engine .container-search-engine {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
    border-radius: 100px;
    padding: 5px;
    transition: width .15s cubic-bezier(0, 0, 0, 1), background-color .3s ease;
    box-sizing: initial;
    -webkit-background-clip: padding-box;
    -webkit-transform: translateZ(0);
    border: 1px solid transparent;
    cursor: pointer;
}

.header-search-engine .container-search-engine .caption-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 25px;
    color: #BDBDBD;
    font-size: 14px;
    font-weight: 600;
}

.hide-caption-heading {
    display: none !important;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.header-search-engine .container-search-engine .fields {
    display: none;
   transition: all 0.4s ease;
}

.fields-show {
    display: block !important;
}
.header-search-engine .container-search-engine .fields .fields-inner{
    display: flex;
    align-self: stretch;
    flex: 1;
}
.header-search-engine .container-search-engine .fields .input-wrapper {
    flex-grow: 1;
}

.header-search-engine .container-search-engine .fields .input-wrapper .field-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.header-search-engine .container-search-engine .fields .input-wrapper .field-wrapper .input-search {
    border: 0;
    outline: none;
    background: transparent;
    margin-left: 10px;
    width: 100%;
}

.header-search-engine .container-search-engine .fields .input-wrapper .field-wrapper .input-search::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 12px;
    color: #BDBDBD;
}
.header-search-engine .container-search-engine .search-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    font-size: 20px;
    z-index: 1;
    border: 4px solid #002A0F;
    background-color: #F5D823;
    color: #000;
    cursor: pointer;
    border-radius: 100%;
}
/* mobile Menu */
.mobile-menu{
    display: none;
}
.mobile-toggle{
    display: none;
    text-align: end;
    padding: 15px 0;
}
.mobile-toggle i{
    font-size: 36px;
    font-weight: 600;
    color: #002a11;
}
.header-backhistory{
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 9999;
}
/* ------------- End Header Css --------------- */
/* mobile responsive */
@media (max-width:991px){
    .main-header .logo-box .logo img {
        width: 100%;
    }
}
@media (max-width:991px){ 
    .navigation-bar {
        display: none !important;
    } 
    .mobile-menu{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .mobile-menu .join-healer-btn{
        margin-right: 20px;
        background-color: #f5d823;
        color: #002a0f;
        padding: 8px 20px;
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 1;
        font-style: normal;
        font-weight: 600;
        border: 2px solid #f5d823;
        border-radius: 5px;
        /* text-transform: capitalize; */
        transition: all .3s ease-in;
    }
    .mobile-toggle{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: fit-content;
    }     
    .mobile-menu .mobile-navigation{
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 80vw;
        height: 100%;
        background-color:#FFECD8;
        z-index: 9999;
        visibility: hidden;
        opacity: 0.5;
        transform: translateX(-100%);
        transition: all 0.4s ease-in-out;

    }
    .mobile-menu .backgroundOverlay{
        position: fixed;
        bottom: 0;
        left: 0;
        top: 0;
        background-color: rgb(0 0 0 / 50%);
        z-index: 0;
        height: 100%;
        transition: 0.3ms ease-in-out;
    }
    .mobile-menu .mobile-navigation.show + .backgroundOverlay{
        width: 100dvw;
        right: 0;
        transition: 0.3s ease-in-out;
    }
    .mobile-menu .mobile-navigation h4{
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid #002a11;
        padding:15px 20px;
        color: #002a11;
    }
    .mobile-menu .mobile-navigation ul{
        padding: 10px 0;
    }
    .mobile-menu .mobile-navigation li{
        width: 100%;
    }
    .mobile-menu .mobile-navigation li a{
        color: #002a11;
        padding: 13px 25px;
        border-bottom: 1px solid #fff;
        font-size: 14px;
        font-weight: 600;
        display: block;
    }
    .main-header .logo-box .logo {
        max-width: 85px;
        border-radius: 0px 0px 10px 10px;

    }
    .main-header .logo-box .logo img{
        width: 50px;
    }
    .mobile-navigation.show{
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
    }
    .mobile-navigation .menuclose{
        position: absolute;
        right: 0;
        top: 0;
        padding: 6px 10px;
    }
    .mobile-navigation .menuclose i{
        font-size: 26px;
        font-weight: 300;
    }
   
    .header-backhistory .inner-box{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding: 15px;
        background-color: #002a11;
    }
    .header-backhistory .inner-box .backbutton i{
        color: #fff;
        font-weight: 500;
        font-size: 30px;
        line-height: 1;
    }
    .header-backhistory .inner-box .pagename-heading{
        margin: auto;
    }
    .header-backhistory .inner-box .pagename-heading h6{
        font-size: 18px;
        font-weight: 600;
        color:#fff;
    }   
}
@media (max-width:575px){

    .main-header .container{
        max-width: 100%;
    }
    .header-backhistory .inner-box .pagename-heading h6{
        font-size: 16px;
    }
    .main-header .logo-box .logo a {
        padding:5px !important;
    }
    .header-backhistory .inner-box{
        position: relative;
    }
    .header-backhistory .inner-box .backbutton{
        position: relative;
        z-index: 5;
    }
    .header-backhistory .inner-box .pagename-heading{
        position: absolute;
        right: 0;
        left: 0;
        text-align: center;
        padding: 0 60px;
        z-index: 0;
    }
}
@media (min-width: 769px) and (max-width: 1281px) { 
    .main-header .navigation-bar ul li .join-healer-btn,
    .main-header .navigation-bar ul li a,
    .main-header .navigation-bar .dropdown-login .dropdown-btn {
        font-size: 12px !important;
    } 
}