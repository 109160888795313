/* -------------- Footer Css---------------- */
.footer {
    position: relative;
}

.footer .footer-section {
    width: 100%;
    height: 100%;
    background-image: url(/images/footer-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 90px 0 60px;
}

.footer .footer-section .footer-content {
    text-align: center;
    margin-top: -55px;
}

.footer .footer-section .footer-content .logo img {
    margin-bottom: 15px;
    width: 130px;
    height: 130px;
}

.footer .footer-section .footer-content p {
    color: #DDDDDD;
    margin-bottom:10px;
}

.footer .footer-section .footer-content ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: 0;
    padding: 0;
}

.footer .footer-section .footer-content ul li a {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5D823;
    border: 1px solid #F5D823;
    border-radius: 100px;

}

.footer .footer-section .footer-content ul li a i {
    font-size: 14px;
    line-height: 1;
}

.footer .footer-section .footer-content ul li a i:hover {
    color: #fff;
    background-color: transparent;
}

.footer .footer-section .heading-footer {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.footer .footer-section .footer-contact ul {
    padding: 0;
    margin: 0;
}

.footer .footer-section .footer-contact ul li {
    position: relative;
    padding-left: 40px;
    margin-bottom: 10px;
}

.footer .footer-section .footer-contact ul li i {
    position: absolute;
    left: 5px;
    color: #F5D823;
    font-size: 20px;
}

.footer .footer-section .footer-contact ul li a {
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    display: block;
    font-weight: 600;
}

.footer .footer-section .footer-contact ul li a:hover {
    color: #F5D823;
}

.footer .footer-section .quick-link ul {
    column-count: 2;
    margin: 0;
    padding: 0;
}

.footer .footer-section .quick-link ul li {
    margin-bottom: 10px;
}

.footer .footer-section .quick-link ul li a {
    font-size: 14px;
    line-height: 26px;
    color: #ddd;
}

.footer .footer-section .quick-link ul li a:hover {
    color: #F5D823;
}

.footer .footer-bottom {
    background: #1B1A1A;
    padding: 12px 10px;
    text-align: center;
}

.footer .footer-bottom .copyright {
    font-weight: 600;
    color: #fff;
    margin: 0;
}

.footer .footer-bottom .copyright a {
    color: #F5D823;
}

/*------------- End Footer Css ---------------*/
.m-footer{
    position: fixed;
    z-index: 99;
    background: #fff;
    bottom: -1px;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0 0 12px #0000001f;
    box-shadow: 0 0 12px #0000001f;
    display: none;
    overflow: hidden;
}
.m-footer ul{
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.m-footer ul li{
    height: 50px;
    text-transform: capitalize;
    width: 20%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #555;
    position: relative;
}
.m-footer ul li a,
.m-footer ul li button{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    color: inherit;
    height: 100%;
    justify-items: center;
    row-gap: 2px;
    font-size: 12px !important;
    font-weight: 600;
    position: relative;
}
.m-footer ul li a span,
.m-footer ul li button span{
    line-height: 1;
    text-transform: none;
    font-weight: 600;
    display: block;
    position: absolute;
    left: 0;
    bottom: 6px;
    right: 0;
}
.m-footer ul li a img,
.m-footer ul li button img{
    height: 20px;
    width: 20px;
    margin-bottom: 14px;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.m-footer ul li:last-child a img{
    border-radius: 100px;
}
.m-footer ul li .accountbtn{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;
    color: inherit;
    padding: 5px;
    outline: none;
    border: 0;
    background: #ffff;
}
.m-footer ul li.active span,
.m-footer ul li.active button {
    color: #002A0F;
}
.m-footer ul li.active img,
.m-footer ul li.active img{
    filter: brightness(0);
}
.m-footer ul li.active::after {
    content: "";
    height: 4px;
    border-radius: 10px 10px 0 0;
    display: block;
    background: #002A0F;
    position: absolute;
    left: 0;
    bottom: -1px;
    right: 0;
}

/* mobile responsive */
@media (max-width:991px){
    .modal-signup .modal-header .btn-close{       
        background: #002A0F;
        color: #fff;
        font-size: 18px;
        border-radius: 5px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border: 0;
    }   
    .modal-signup .modal-body{
        padding: 25px 15px;
        background: #FFECD8;
        border-radius: 10px;
    }
    .modal-signup .sign-card h4{
        font-size: 20px;
        color: #002a11;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #ffffff;
    }
    .modal-signup .sign-card ul li:not(:last-child){
        margin-bottom: 10px;
    }
    .modal-signup .sign-card ul li a,
    .modal-signup .sign-card ul li button{
        border: 1px solid #002A0F;
        color: #ffffff;
        background: #002A0F;
        border-radius: 5px;
        padding: 10px 25px;
        font-size: 14px;
        font-weight: 600;
        display:    flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        text-align: center;
        max-width: 220px;
        margin: auto;
        width: 100%;
    }   
    .modal-signup .modal-header{
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        padding: 0;
        background: transparent;
        z-index: 2;
    }   
}
@media (max-width:767px){
    .footer {
        display: none;
    }
    .footer-mb{
        padding-bottom: 50px;
    }
    .footer {
        display: none;
    }
    .m-footer{
        display: block;
    }
    .modal-signup{
        padding: calc(100% - 90%);
    }    
}
@media (max-width: 575px) {
    .m-footer ul li.active a img, .m-footer ul li.active button img {
        filter: brightness(0);
    }
    
    .m-footer ul li.active a span, .m-footer ul li.active button span {
        color: #002A0F;
    }
    .m-footer ul li:last-child.active a img, .m-footer ul li:last-child.active button img {
       filter: brightness(1);
    }
}
